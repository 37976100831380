import { SectionPatchNotesBodyProps } from "../../sections/patch-notes/body";
import { SectionPatchNotesHeaderProps } from "../../sections/patch-notes/header";
import { SectionArticleShareProps } from "../../sections/article/share";
import { SectionArticleTagsProps } from "../../sections/article/tags";

export enum SectionType {
  PATCH_NOTES_BODY = "patch_notes_body",
  PATCH_NOTES_HEADER = "patch_notes_header",
  ARTICLE_SHARE = "article_share",
  ARTICLE_TAGS = "article_tags",
}

export type Section =
  | {
      key: string;
      type: SectionType.PATCH_NOTES_BODY;
      props: SectionPatchNotesBodyProps;
    }
  | {
      key: string;
      type: SectionType.PATCH_NOTES_HEADER;
      props: SectionPatchNotesHeaderProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_SHARE;
      props: SectionArticleShareProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_TAGS;
      props: SectionArticleTagsProps;
    };
