import styled from "styled-components";

import {
  Line as ShareLine,
  HorizontalLine as ShareHorizontalLine,
  BottomHex as ShareBottomHex,
} from "../../sections/article/share/style";

import { COLOR_BACKGROUND_DARK_LIGHT, COLOR_OUTLINE_DARKER, COLOR_TEXT_WHITE } from "../../layouts/default/theme";

export const Wrapper = styled.section`
  background-color: ${COLOR_BACKGROUND_DARK_LIGHT};
  color: ${COLOR_TEXT_WHITE};
  padding-bottom: 100px;

  /* NOTE 2019-10-15 jeremboo: HACK the share design */
  ${ShareLine}, ${ShareHorizontalLine} {
    background: ${COLOR_OUTLINE_DARKER};
  }
  ${ShareBottomHex} .st0 {
    stroke: ${COLOR_OUTLINE_DARKER};
  }
`;
