import React from "react";

import PatchNotesTemplate from "../../../../../lib/templates/patch-notes";
import { SectionType, Section } from "../../../../../lib/templates/patch-notes/typings";

import { transformContentstackPropsToSectionProps as transformShare } from "../../../../../lib/sections/article/share/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPatchNotesBody } from "../../../../../lib/sections/patch-notes/body/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPatchNotesHeader } from "../../../../../lib/sections/patch-notes/header/transforms/contentstack";

import { ContentstackSection, TemplateContext } from "./typings";
import Meta from "../../../../../lib/components/meta";
import { openGraphImageUrl, twitterImageUrl } from "../../../../../lib/assets/share";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, PageProps } from "gatsby";
import transformAllInternalArticlesQueryNodeToDefaultTemplateContextData from "../../transforms/all-internal-articles-query-node-to-default-template-context-data";

interface PageData {
  // TODO: unravel types.
  all: {
    nodes: any;
  };
  configuration: any;
}

interface Props extends PageProps<PageData, TemplateContext> {
  path: string;
}

const byPublishedLocale =
  (locale: string) =>
  (node: any): boolean =>
    node.publish_details.locale === locale;

const ContentType: React.FC<Props> = (props) => {
  const { pageContext, path, data: __data } = props;
  const language = pageContext.language;
  const byContextLocale = byPublishedLocale(language);
  const node = __data.all.nodes.filter(byContextLocale)[0];
  const data = transformAllInternalArticlesQueryNodeToDefaultTemplateContextData(node);
  const configurationNode = __data.configuration.edges[0].node;

  const intl = useIntl();
  // console.log(
  //   `Building patch notes for ${props.pageContext.language} as ${props.location.pathname} // ${data.title} (${__data.node.publish_details.locale}) `,
  // );
  const headerSection: ContentstackSection = {
    type: SectionType.PATCH_NOTES_HEADER,
    props: {
      link: {
        url: path,
        internal: true,
      },
      authors: !data.authors ? undefined : data.authors.map(({ name }) => ({ name })),
      date: data.published_date,
      title: data.title,
      imageUrl: data.imageUrl,
    },
  };

  const tagsSection: ContentstackSection = {
    type: SectionType.ARTICLE_TAGS,
    props: {
      tags: data.article_tags,
      patchNotes: true,
      tagsTitle: configurationNode.articles.tags_title,
    },
  };

  const shareSection: ContentstackSection = {
    type: SectionType.ARTICLE_SHARE,
    props: {
      title: intl.formatMessage({ id: "share-this-article.title" }),
      url: {
        url: path,
        internal: true,
      },
    },
  };

  const sections: ContentstackSection[] = [
    headerSection,
    ...data.sections,
    tagsSection,
    shareSection,
  ] as ContentstackSection[];

  return (
    <React.Fragment>
      <Meta
        title={data.title}
        description={data.description}
        openGraph={{
          image: {
            url: data.imageUrl ? data.imageUrl : openGraphImageUrl,
          },
        }}
        twitter={{
          image: {
            url: data.imageUrl ? data.imageUrl : twitterImageUrl,
          },
        }}
      />
      <PatchNotesTemplate sections={dataSectionsToArticleSections(sections)}></PatchNotesTemplate>
    </React.Fragment>
  );
};
export default ContentType;

const dataSectionsToArticleSections = (dataSections: ContentstackSection[]): Section[] => {
  return dataSections
    .map((csSection, index) => {
      let props;

      switch (csSection.type) {
        case SectionType.ARTICLE_SHARE:
          props = transformShare(csSection.props);
          break;

        case SectionType.PATCH_NOTES_BODY:
          props = transformPatchNotesBody(csSection.props);
          break;

        case SectionType.PATCH_NOTES_HEADER:
          props = transformPatchNotesHeader(csSection.props);
          break;

        case SectionType.ARTICLE_TAGS:
          props = csSection.props;
          break;
      }

      if (!props) {
        console.warn(`Unable to transform section. (type: ${csSection.type})`);
        return null;
      }

      return {
        type: csSection.type,
        key: `section_${index}`,
        props,
      };
    })
    .filter((section): section is Section => section !== null);
};

export const query = graphql`
  # If the page query cache needs to change with context changes, then we need
  # $path and a query for contextChangeNotInvalidingQueryWorkaround
  # Since this page only uses locale and url, and those are essentially the query
  # cache key anyways, then we don't need to add this hack.
  #
  # query($language: String!, $url: String!, $path: String!) {
  # use our workaround and alias null result to something short (_)
  # that doesn't expose what it does and saves few bytes
  #_: contextChangeNotInvalidingQueryWorkaround(path: $path)

  query ($language: String!, $url: String!) {
    all: allContentstackArticles(
      filter: { publish_details: { locale: { eq: $language } }, url: { url: { eq: $url } } }
    ) {
      nodes {
        publish_details {
          locale
        }
        ...FullPatchNotesFragment
      }
    }
    configuration: allContentstackWebsiteConfiguration(filter: { publish_details: { locale: { eq: $language } } }) {
      edges {
        node {
          articles {
            tags_title
          }
        }
      }
    }
  }
`;

export const FullPatchNotesFragment = graphql`
  fragment FullPatchNotesFragment on Contentstack_articles {
    uid
    url {
      url
    }
    title
    date
    description
    category {
      title
      machine_name
      url {
        url
      }
    }
    banner {
      url
    }
    author {
      title
    }
    article_type
    patch_notes_body {
      patch_notes {
        html
      }
    }
    article_tags {
      title
      machine_name
      is_hidden
      url {
        url
      }
    }
  }
`;
