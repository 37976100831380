import React from "react";

import SectionPatchNotesBody from "../../sections/patch-notes/body";
import SectionPatchNotesHeader from "../../sections/patch-notes/header";
import SectionArticleShare from "../../sections/article/share";
import SectionArticleTags from "../../sections/article/tags";

import { SectionType, Section as TemplateSection } from "./typings";

import { Wrapper } from "./style";

const SECTION_MAP: { [type in SectionType]: any } = {
  [SectionType.PATCH_NOTES_BODY]: SectionPatchNotesBody,
  [SectionType.PATCH_NOTES_HEADER]: SectionPatchNotesHeader,
  [SectionType.ARTICLE_SHARE]: SectionArticleShare,
  [SectionType.ARTICLE_TAGS]: SectionArticleTags,
};

interface Props {
  sections: TemplateSection[];
}

const Template: React.FC<Props> = ({ sections }) => {
  return (
    <Wrapper>
      {sections.map(({ type, key, props }, sectionIndex) => {
        const CurrentSection = SECTION_MAP[type];
        if (!CurrentSection) {
          console.warn(`Unsupported section type. (type: ${type})`);
          return;
        }

        return <CurrentSection key={key} {...props} />;
      })}
    </Wrapper>
  );
};

export default Template;
