import React from "react";
import { SectionPatchNotesHeaderProps } from "..";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionPatchNotesHeaderContentstackProps
  extends Pick<SectionPatchNotesHeaderProps, "title" | "authors"> {
  link: UrlFieldType;
  imageUrl?: ImageAssetUrl;
  date: string;
}

export const transformContentstackPropsToSectionProps = ({
  imageUrl,
  date,
  ...otherProps
}: SectionPatchNotesHeaderContentstackProps): SectionPatchNotesHeaderProps => {
  return {
    ...otherProps,
    media: imageUrl ? <ComponentImage src={imageUrl} objectFit={"cover"} alt="" /> : undefined,
    date: new Date(date),
  };
};
